import React from "react";
import { navigate } from "gatsby";

const CategoryCard = ({ card }) => {
  const handleNavigate = () => {
    navigate(`/Category/${card.id}`);
  };

  return (
    <div className="mr-4" onClick={handleNavigate} style={{ cursor: "pointer" }}>
      <div className="pt-1 pl-2 sm:full" style={{ width: 344 }}>
        <div className="flex relative" style={{ height: 198, width: "100%" }}>
          <div
            style={{
              backgroundColor: card.color,
              opacity: 0.7,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          ></div>
          <img
            className="rounded"
            src={card.image.url}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "relative",
              zIndex: 0,
            }}
            alt={card.titulo ?? card.nombre}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              fontWeight: "bold",
              fontSize: "24px",
              textAlign: "center",
              zIndex: 2,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "90%",
            }}
          >
            {card.title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
