import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CategoryCard from "./categoryCard";
import ContentCard from "./contentCard";
import { Link } from "gatsby";

const CategoryBlock = ({
  block,
  hideOverlayAndImage,
  idCategory,
  categoryPath = "Category",
  userHasSuscription,
  useDetailedLink = false,
}) => {
  console.log(block);

  // Flecha izquierda
  const LeftArrow = () => {
    const { scrollPrev } = React.useContext(VisibilityContext);
    const handlePrevClick = () => {
      console.log("Left arrow clicked");
      scrollPrev();
    };

    return (
      <div onClick={handlePrevClick} style={{ cursor: "pointer" }}>
        <ArrowBackIosIcon />
      </div>
    );
  };

  // Flecha derecha
  const RightArrow = () => {
    const { scrollNext } = React.useContext(VisibilityContext);
    const handleNextClick = () => {
      console.log("Right arrow clicked");
      scrollNext();
    };

    return (
      <div onClick={handleNextClick} style={{ cursor: "pointer" }}>
        <ArrowForwardIosIcon />
      </div>
    );
  };

  // Si hideOverlayAndImage es true, usaremos ContentCard; de lo contrario, CategoryCard
  const CardComponent = hideOverlayAndImage ? ContentCard : CategoryCard;

  return (
    <div className="mb-8">
      <div className="flex w-full justify-between items-center mb-4">
        <h3 className="text-sc-titles">{block.titulo}</h3>
        <Link to={useDetailedLink ? `/${categoryPath}`: `/${categoryPath}/${idCategory}/${block._id}`}>
          Ver todo
        </Link>
      </div>

      {/* Vista para pantallas grandes */}
      <div className="hidden md:block">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {block.cards.map((card) => (
            <CardComponent
              key={card.id}
              card={card}
              itemId={card.id} // Asegúrate de que cada elemento tenga un itemId único
              userHasSuscription={userHasSuscription ?? null}
            />
          ))}
        </ScrollMenu>
      </div>

      {/* Vista para móviles */}
      <div className="block md:hidden flex overflow-x-scroll w-full scroll-hidden">
        {block.cards.map((card) => (
          <CardComponent
            key={card.id}
            card={card}
            userHasSuscription={userHasSuscription ?? null}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoryBlock;